export const imgData = [
  {
    id: 1,
    category: "graphic",
    urlName: "ctbc_birthday",
    name: "中信銀行 生日賀卡",
    portfolioCover: require("../img/work/ctbc_birthday/ctbc_birthday_thumbnail.jpg"),
    workCover: require("../img/work/ctbc_birthday/ctbc_birthday1.jpg"),
    gallery: [
      "./img/work/ctbc_birthday/ctbc_birthday1.jpg",
      "./img/work/ctbc_birthday/ctbc_birthday2.jpg",
      "./img/work/ctbc_birthday/ctbc_birthday3.jpg",
      "./img/work/ctbc_birthday/ctbc_birthday4.jpg",
    ],
    completionDate: { year: "2021", month: "Dec" },
    liveDemo: "",
  },
  {
    id: 2,
    category: "website",
    urlName: "mli_lunar",
    name: "三商美邦人壽 中秋賀卡",
    portfolioCover: require("../img/work/mli_lunar/mli_lunar_thumbnail.jpg"),
    workCover: require("../img/work/mli_lunar/mli_lunar_workcover.jpg"),
    gallery: [
      "./img/work/mli_lunar/mli_lunar1.jpg",
      "./img/work/mli_lunar/mli_lunar2.jpg",
      "./img/work/mli_lunar/mli_lunar3.jpg",
    ],
    completionDate: { year: "2023", month: "Sep" },
    liveDemo: "https://richad.com.tw/test/230913-mli-moon_fest/",
  },
  {
    id: 3,
    category: "graphic",
    urlName: "sc_priority_q4",
    name: "渣打銀行 優先理財",
    portfolioCover: require("../img/work/sc_priority_q4/sc_priority_q4_1_thumbnail.jpg"),
    workCover: require("../img/work/sc_priority_q4/sc_priority_q4_1.jpg"),
    gallery: [
      "./img/work/sc_priority_q4/sc_priority_q4_1.jpg",
      "./img/work/sc_priority_q4/sc_priority_q4_2.jpg",
      "./img/work/sc_priority_q4/sc_priority_q4_3.jpg",
      "./img/work/sc_priority_q4/sc_priority_q4_4.jpg",
    ],
    completionDate: { year: "2023", month: "Sep" },
    liveDemo: "",
  },
  {
    id: 4,
    category: "graphic",
    urlName: "landbank_recruit",
    name: "土地銀行新進人員招募DM",
    portfolioCover: require("../img/work/landbank_recruit/landbank_recruit1_thumbnail.jpg"),
    workCover: require("../img/work/landbank_recruit/landbank_recruit1.jpg"),
    gallery: [
      "./img/work/landbank_recruit/landbank_recruit1.jpg",
      "./img/work/landbank_recruit/landbank_recruit2.jpg",
    ],
    completionDate: { year: "2023", month: "Feb" },
    liveDemo: "",
  },
  {
    id: 5,
    category: "website",
    urlName: "taishin_summer_exchange",
    name: "台新銀行 換匯活動",
    portfolioCover: require("../img/work/taishin_summer_exchange/taishin_summer_exchange1_thumbnail.jpg"),
    workCover: require("../img/work/taishin_summer_exchange/taishin_summer_exchange1.jpg"),
    gallery: [
      "./img/work/taishin_summer_exchange/taishin_summer_exchange1.jpg",
      "./img/work/taishin_summer_exchange/taishin_summer_exchange2.jpg",
      "./img/work/taishin_summer_exchange/taishin_summer_exchange3.jpg",
    ],
    completionDate: { year: "2022", month: "Jan" },
    liveDemo: "",
  },
  {
    id: 6,
    category: "graphic",
    urlName: "mli_dragonboat",
    name: "三商美邦人壽 端午賀卡",
    portfolioCover: require("../img/work/mli_dragonboat/mli_dragonboat2_thumbnail.jpg"),
    workCover: require("../img/work/mli_dragonboat/mli_dragonboat2.jpg"),
    gallery: [
      "./img/work/mli_dragonboat/mli_dragonboat1.jpg",
      "./img/work/mli_dragonboat/mli_dragonboat2.jpg",
      "./img/work/mli_dragonboat/mli_dragonboat3.jpg",
    ],
    completionDate: { year: "???", month: "???" },
    liveDemo: "",
  },
  {
    id: 7,
    category: "package",
    urlName: "ve_wong_noodles",
    name: "味王 大食客包裝設計",
    portfolioCover: require("../img/work/ve_wong_noodles/ve_wong_noodles5_thumbnail.jpg"),
    workCover: require("../img/work/ve_wong_noodles/ve_wong_noodles5.jpg"),
    gallery: [
      "./img/work/ve_wong_noodles/ve_wong_noodles1.jpg",
      "./img/work/ve_wong_noodles/ve_wong_noodles2.jpg",
      "./img/work/ve_wong_noodles/ve_wong_noodles3.jpg",
      "./img/work/ve_wong_noodles/ve_wong_noodles4.jpg",
      "./img/work/ve_wong_noodles/ve_wong_noodles5.jpg",
    ],
    completionDate: { year: "2023", month: "Oct" },
    liveDemo: "",
  },
  {
    id: 8,
    category: "package",
    urlName: "ve_wong_fast_noodles",
    name: "味王 快煮麵包裝設計",
    portfolioCover: require("../img/work/ve_wong_fast_noodles/ve_wong_fast_noodles1_thumbnail.jpg"),
    workCover: require("../img/work/ve_wong_fast_noodles/ve_wong_fast_noodles1.jpg"),
    gallery: [
      "./img/work/ve_wong_fast_noodles/ve_wong_fast_noodles1.jpg",
      "./img/work/ve_wong_fast_noodles/ve_wong_fast_noodles2.jpg",
      "./img/work/ve_wong_fast_noodles/ve_wong_fast_noodles3.jpg",
      "./img/work/ve_wong_fast_noodles/ve_wong_fast_noodles4.jpg",
    ],
    completionDate: { year: "2022", month: "Feb" },
    liveDemo: "",
  },
  {
    id: 9,
    category: "website",
    urlName: "sinopac_vip_2023",
    name: "永豐銀行 尊榮升等2023",
    portfolioCover: require("../img/work/sinopac_vip_2023/sinopac_vip2_thumbnail.jpg"),
    workCover: require("../img/work/sinopac_vip_2023/sinopac_vip2.jpg"),
    gallery: [
      "./img/work/sinopac_vip_2023/sinopac_vip2.jpg",
      "./img/work/sinopac_vip_2023/sinopac_vip1.jpg",
      "./img/work/sinopac_vip_2023/sinopac_vip3.jpg",
    ],
    completionDate: { year: "2022", month: "Dec" },
    liveDemo: "",
  },
  {
    id: 10,
    category: "graphic",
    urlName: "ttl",
    name: "臺灣菸酒公司 易洗樂視覺",
    portfolioCover: require("../img/work/taiwan_tobacco/ttl_detergent2_thumbnail.jpg"),
    workCover: require("../img/work/taiwan_tobacco/ttl_detergent2.jpg"),
    gallery: [
      "./img/work/taiwan_tobacco/ttl_detergent2.jpg",
      "./img/work/taiwan_tobacco/ttl_detergent1.jpg",
      "./img/work/taiwan_tobacco/ttl_detergent3.jpg",
    ],
    completionDate: { year: "2022", month: "May" },
    liveDemo: "",
  },
  {
    id: 11,
    category: "graphic",
    urlName: "sc_house_loan",
    name: "渣打銀行 房貸專案 薦者有份推薦友禮DM",
    portfolioCover: require("../img/work/sc_house_loan/sc_house_loan1_thumbnail.jpg"),
    workCover: require("../img/work/sc_house_loan/sc_house_loan1.jpg"),
    gallery: [
      "./img/work/sc_house_loan/sc_house_loan1.jpg",
      "./img/work/sc_house_loan/sc_house_loan2.jpg",
    ],
    completionDate: { year: "2022", month: "Dec" },
    liveDemo: "",
  },
  {
    id: 12,
    category: "graphic",
    urlName: "richad_thank_card",
    name: "里安國際 2023節日感謝卡",
    portfolioCover: require("../img/work/richad_thank_card/richad_thank_card1_thumbnail.jpg"),
    workCover: require("../img/work/richad_thank_card/richad_thank_card1.jpg"),
    gallery: [
      "./img/work/richad_thank_card/richad_thank_card1.jpg",
      "./img/work/richad_thank_card/richad_thank_card2.jpg",
      "./img/work/richad_thank_card/richad_thank_card3.jpg",
    ],
    completionDate: { year: "2023", month: "Feb" },
    liveDemo: "",
  },
  {
    id: 13,
    category: "website",
    urlName: "taishin_dragon",
    name: "台新銀行 2023換匯活動網頁",
    portfolioCover: require("../img/work/taishin_dragon/taishin_dragon1_thumbnail.jpg"),
    workCover: require("../img/work/taishin_dragon/taishin_dragon1.jpg"),
    gallery: [
      "./img/work/taishin_dragon/taishin_dragon1.jpg",
      "./img/work/taishin_dragon/taishin_dragon2.jpg",
      "./img/work/taishin_dragon/taishin_dragon3.jpg",
      "./img/work/taishin_dragon/taishin_dragon4.jpg",
      "./img/work/taishin_dragon/taishin_dragon5.jpg",
    ],
    completionDate: { year: "2023", month: "Nov" },
    liveDemo: "",
  },
  {
    id: 14,
    category: "graphic",
    urlName: "landbank_gender_equality",
    name: "土地銀行 性別平等海報",
    portfolioCover: require("../img/work/landbank_gender_equality/landbank_gender_equality1_thumbnail.jpg"),
    workCover: require("../img/work/landbank_gender_equality/landbank_gender_equality1.jpg"),
    gallery: [
      "./img/work/landbank_gender_equality/landbank_gender_equality1.jpg",
      "./img/work/landbank_gender_equality/landbank_gender_equality2.jpg",
      "./img/work/landbank_gender_equality/landbank_gender_equality3.jpg",
    ],
    completionDate: { year: "2021", month: "Aug" },
    liveDemo: "",
  },
  {
    id: 15,
    category: "website",
    urlName: "sinopac_stock",
    name: "永豐銀行 聰明理財 掌握投資先機專案網頁",
    portfolioCover: require("../img/work/sinopac_fund/sinopac_fund1_thumbnail.jpg"),
    workCover: require("../img/work/sinopac_fund/sinopac_fund1.jpg"),
    gallery: [
      "./img/work/sinopac_fund/sinopac_fund1.jpg",
      "./img/work/sinopac_fund/sinopac_fund2.jpg",
      "./img/work/sinopac_fund/sinopac_fund3.jpg",
      "./img/work/sinopac_fund/sinopac_fund4.jpg",
      "./img/work/sinopac_fund/sinopac_fund5.jpg",
    ],
    completionDate: { year: "2023", month: "Apr" },
    liveDemo: "",
  },
  {
    id: 16,
    category: "graphic",
    urlName: "sc_priority_welcome",
    name: "渣打銀行 優先私人理財 2023 Welcome Pack",
    portfolioCover: require("../img/work/sc_priority_welcome/sc_priority_welcome1_thumbnail.jpg"),
    workCover: require("../img/work/sc_priority_welcome/sc_priority_welcome1.jpg"),
    gallery: [
      "./img/work/sc_priority_welcome/sc_priority_welcome1.jpg",
      "./img/work/sc_priority_welcome/sc_priority_welcome2.jpg",
      "./img/work/sc_priority_welcome/sc_priority_welcome3.jpg",
    ],
    completionDate: { year: "2023", month: "May" },
    liveDemo: "",
  },
  {
    id: 17,
    category: "website",
    urlName: "taishin_balance",
    name: "台新銀行 平衡型基金行銷網頁",
    portfolioCover: require("../img/work/taishin_balance/taishin_balance3_thumbnail.jpg"),
    workCover: require("../img/work/taishin_balance/taishin_balance3.jpg"),
    gallery: [
      "./img/work/taishin_balance/taishin_balance1.jpg",
      "./img/work/taishin_balance/taishin_balance2.jpg",
      "./img/work/taishin_balance/taishin_balance3.jpg",
    ],
    completionDate: { year: "2022", month: "May" },
    liveDemo: "",
  },
  {
    id: 18,
    category: "website",
    urlName: "taishin_getfirst",
    name: "台新銀行 年終搶頭香網頁",
    portfolioCover: require("../img/work/taishin_getfirst/taishin_getfirst1_thumbnail.jpg"),
    workCover: require("../img/work/taishin_getfirst/taishin_getfirst1.jpg"),
    gallery: [
      "./img/work/taishin_getfirst/taishin_getfirst1.jpg",
      "./img/work/taishin_getfirst/taishin_getfirst2.jpg",
      "./img/work/taishin_getfirst/taishin_getfirst3.jpg",
      "./img/work/taishin_getfirst/taishin_getfirst4.jpg",
    ],
    completionDate: { year: "2023", month: "Dec" },
    liveDemo: "",
  },
  {
    id: 19,
    category: "graphic",
    urlName: "sc_vd_card",
    name: "渣打銀行  VISA金融卡VD續卡遞卡函",
    portfolioCover: require("../img/work/sc_vd_card/sc_vd_card1_thumbnail.jpg"),
    workCover: require("../img/work/sc_vd_card/sc_vd_card1.jpg"),
    gallery: [
      "./img/work/sc_vd_card/sc_vd_card1.jpg",
      "./img/work/sc_vd_card/sc_vd_card2.jpg",
    ],
    completionDate: { year: "2023", month: "Mar" },
    liveDemo: "",
  },
  {
    id: 20,
    category: "website",
    urlName: "taishin_meet",
    name: "台新銀行 見面有禮活動網頁",
    portfolioCover: require("../img/work/taishin_meet/taishin_meet1_thumbnail.jpg"),
    workCover: require("../img/work/taishin_meet/taishin_meet1.jpg"),
    gallery: [
      "./img/work/taishin_meet/taishin_meet1.jpg",
      "./img/work/taishin_meet/taishin_meet2.jpg",
      "./img/work/taishin_meet/taishin_meet3.jpg",
    ],
    completionDate: { year: "2022", month: "Apr" },
    liveDemo: "",
  },
  {
    id: 21,
    category: "website",
    urlName: "sinopac_vet",
    name: "永豐銀行 獸醫師優惠活動網頁",
    portfolioCover: require("../img/work/sinopac_vet/sinopac_vet1_thumbnail.jpg"),
    workCover: require("../img/work/sinopac_vet/sinopac_vet1.jpg"),
    gallery: [
      "./img/work/sinopac_vet/sinopac_vet1.jpg",
      "./img/work/sinopac_vet/sinopac_vet2.jpg",
      "./img/work/sinopac_vet/sinopac_vet3.jpg",
    ],
    completionDate: { year: "2021", month: "Sep" },
    liveDemo: "",
  },
  {
    id: 22,
    category: "graphic",
    urlName: "sc_dragon_card",
    name: "渣打銀行 財富管理 2024龍年一元賀卡",
    portfolioCover: require("../img/work/sc_dragon_card/sc_dragon_card2_thumbnail.jpg"),
    workCover: require("../img/work/sc_dragon_card/sc_dragon_card2.jpg"),
    gallery: [
      "./img/work/sc_dragon_card/sc_dragon_card1.jpg",
      "./img/work/sc_dragon_card/sc_dragon_card2.jpg",
      "./img/work/sc_dragon_card/sc_dragon_card3.jpg",
      "./img/work/sc_dragon_card/sc_dragon_card4.jpg",
    ],
    completionDate: { year: "2023", month: "Dec" },
    liveDemo: "",
  },
  {
    id: 23,
    category: "graphic",
    urlName: "fidelity_campus_21",
    name: "富達投資 2021校園競賽活動視覺設計",
    portfolioCover: require("../img/work/fidelity_campus_21/fidelity_campus_21_2_thumbnail.jpg"),
    workCover: require("../img/work/fidelity_campus_21/fidelity_campus_21_2.jpg"),
    gallery: [
      "./img/work/fidelity_campus_21/fidelity_campus_21_1.jpg",
      "./img/work/fidelity_campus_21/fidelity_campus_21_2.jpg",
      "./img/work/fidelity_campus_21/fidelity_campus_21_3.jpg",
      "./img/work/fidelity_campus_21/fidelity_campus_21_4.jpg",
      "./img/work/fidelity_campus_21/fidelity_campus_21_5.jpg",
      "./img/work/fidelity_campus_21/fidelity_campus_21_6.jpg",
    ],
    completionDate: { year: "2022", month: "Feb" },
    liveDemo: "",
  },
  {
    id: 24,
    category: "graphic",
    urlName: "sc_downgrade",
    name: "渣打銀行 優先理財 客戶降等通知信",
    portfolioCover: require("../img/work/sc_downgrade/sc_downgrade1_thumbnail.jpg"),
    workCover: require("../img/work/sc_downgrade/sc_downgrade1.jpg"),
    gallery: ["./img/work/sc_downgrade/sc_downgrade1.jpg"],
    completionDate: { year: "2023", month: "Jun" },
    liveDemo: "",
  },
  {
    id: 25,
    category: "website",
    urlName: "taishin_birth",
    name: "台新銀行 生日慶活動網頁",
    portfolioCover: require("../img/work/taishin_birth/taishin_birth1_thumbnail.jpg"),
    workCover: require("../img/work/taishin_birth/taishin_birth1.jpg"),
    gallery: [
      "./img/work/taishin_birth/taishin_birth1.jpg",
      "./img/work/taishin_birth/taishin_birth2.jpg",
      "./img/work/taishin_birth/taishin_birth3.jpg",
    ],
    completionDate: { year: "2022", month: "Feb" },
    liveDemo: "",
  },
  {
    id: 26,
    category: "graphic",
    urlName: "twfhclife_cover",
    name: "臺銀人壽 保單封面設計",
    portfolioCover: require("../img/work/twfhclife_cover/twfhclife_cover1_thumbnail.jpg"),
    workCover: require("../img/work/twfhclife_cover/twfhclife_cover1.jpg"),
    gallery: [
      "./img/work/twfhclife_cover/twfhclife_cover1.jpg",
      "./img/work/twfhclife_cover/twfhclife_cover2.jpg",
      "./img/work/twfhclife_cover/twfhclife_cover3.jpg",
    ],
    completionDate: { year: "2023", month: "Dec" },
    liveDemo: "",
  },
  {
    id: 27,
    category: "website",
    urlName: "taishin_oversea",
    name: "台新銀行 海外債券活動視覺設計",
    portfolioCover: require("../img/work/taishin_oversea/taishin_oversea1_thumbnail.jpg"),
    workCover: require("../img/work/taishin_oversea/taishin_oversea1.jpg"),
    gallery: [
      "./img/work/taishin_oversea/taishin_oversea1.jpg",
      "./img/work/taishin_oversea/taishin_oversea2.jpg",
      "./img/work/taishin_oversea/taishin_oversea3.jpg",
      "./img/work/taishin_oversea/taishin_oversea4.jpg",
    ],
    completionDate: { year: "2023", month: "Jan" },
    liveDemo: "",
  },
  {
    id: 28,
    category: "graphic",
    urlName: "sc_ease",
    name: "渣打銀行 優逸理財 Q1視覺設計",
    portfolioCover: require("../img/work/sc_ease/sc_ease1_thumbnail.jpg"),
    workCover: require("../img/work/sc_ease/sc_ease1.jpg"),
    gallery: [
      "./img/work/sc_ease/sc_ease1.jpg",
      "./img/work/sc_ease/sc_ease2.jpg",
      "./img/work/sc_ease/sc_ease3.jpg",
      "./img/work/sc_ease/sc_ease4.jpg",
    ],
    completionDate: { year: "2024", month: "Jan" },
    liveDemo: "",
  },
  {
    id: 29,
    category: "graphic",
    urlName: "sc_priority_mail",
    name: "渣打銀行 優先私人理財信函設計",
    portfolioCover: require("../img/work/sc_priority_mail/sc_priority_mail1_thumbnail.jpg"),
    workCover: require("../img/work/sc_priority_mail/sc_priority_mail1.jpg"),
    gallery: [
      "./img/work/sc_priority_mail/sc_priority_mail1.jpg",
      "./img/work/sc_priority_mail/sc_priority_mail2.jpg",
      "./img/work/sc_priority_mail/sc_priority_mail3.jpg",
      "./img/work/sc_priority_mail/sc_priority_mail4.jpg",
    ],
    completionDate: { year: "2023", month: "Dec" },
    liveDemo: "",
  },
  {
    id: 30,
    category: "graphic",
    urlName: "sc_priority_24q1",
    name: "渣打銀行 優先理財 2024Q1視覺設計",
    portfolioCover: require("../img/work/sc_priority_24q1/sc_priority_24q1_1_thumbnail.jpg"),
    workCover: require("../img/work/sc_priority_24q1/sc_priority_24q1_1.jpg"),
    gallery: [
      "./img/work/sc_priority_24q1/sc_priority_24q1_1.jpg",
      "./img/work/sc_priority_24q1/sc_priority_24q1_2.jpg",
      "./img/work/sc_priority_24q1/sc_priority_24q1_3.jpg",
      "./img/work/sc_priority_24q1/sc_priority_24q1_4.jpg",
    ],
    completionDate: { year: "2024", month: "???" },
    liveDemo: "",
  },
  {
    id: 31,
    category: "graphic",
    urlName: "sc_new_open",
    name: "渣打銀行 北高雄分行開幕活動DM設計",
    portfolioCover: require("../img/work/sc_new_open/sc_new_open1_thumbnail.jpg"),
    workCover: require("../img/work/sc_new_open/sc_new_open1.jpg"),
    gallery: [
      "./img/work/sc_new_open/sc_new_open1.jpg",
      "./img/work/sc_new_open/sc_new_open2.jpg",
    ],
    completionDate: { year: "2023", month: "Nov" },
    liveDemo: "",
  },
  {
    id: 32,
    category: "graphic",
    urlName: "sc_house_loan_dm",
    name: "渣打銀行 理財型房貸操作說明 DM設計",
    portfolioCover: require("../img/work/sc_house_loan_dm/sc_house_loan_dm1_thumbnail.jpg"),
    workCover: require("../img/work/sc_house_loan_dm/sc_house_loan_dm1.jpg"),
    gallery: ["./img/work/sc_house_loan_dm/sc_house_loan_dm1.jpg"],
    completionDate: { year: "2023", month: "Aug" },
    liveDemo: "",
  },
  {
    id: 33,
    category: "graphic",
    urlName: "sc_line_dm",
    name: "渣打銀行 優逸理財LINE專屬限時新戶活動DM設計",
    portfolioCover: require("../img/work/sc_line_dm/sc_line_dm1_thumbnail.jpg"),
    workCover: require("../img/work/sc_line_dm/sc_line_dm1.jpg"),
    gallery: ["./img/work/sc_line_dm/sc_line_dm1.jpg"],
    completionDate: { year: "2023", month: "Jun" },
    liveDemo: "",
  },
  {
    id: 34,
    category: "package",
    urlName: "bidu_restaurant",
    name: "擺渡人 菜單設計",
    portfolioCover: require("../img/work/bidu_restaurant/bidu_restaurant_menu1_thumbnail.jpg"),
    workCover: require("../img/work/bidu_restaurant/bidu_restaurant_menu1.jpg"),
    gallery: [
      "./img/work/bidu_restaurant/bidu_restaurant_menu1.jpg",
      "./img/work/bidu_restaurant/bidu_restaurant_menu2.jpg",
    ],
    completionDate: { year: "2024", month: "May" },
    liveDemo: "",
  },
  {
    id: 35,
    category: "package",
    urlName: "ve_wong_instant",
    name: "味王食品 調理包系列包裝設計",
    portfolioCover: require("../img/work/ve_wong_instant/ve_wong_instant2_thumbnail.jpg"),
    workCover: require("../img/work/ve_wong_instant/ve_wong_instant1.jpg"),
    gallery: [
      "./img/work/ve_wong_instant/ve_wong_instant1.jpg",
      "./img/work/ve_wong_instant/ve_wong_instant2.jpg",
      "./img/work/ve_wong_instant/ve_wong_instant3.jpg",
    ],
    completionDate: { year: "2021", month: "Aug" },
    liveDemo: "",
  },
  {
    id: 36,
    category: "graphic",
    urlName: "ctbc_birthday_card",
    name: "中信銀行 生日賀卡",
    portfolioCover: require("../img/work/ctbc_birthday_card/ctbc_birthday_card3_thumbnail.jpg"),
    workCover: require("../img/work/ctbc_birthday_card/ctbc_birthday_card3.jpg"),
    gallery: [
      "./img/work/ctbc_birthday_card/ctbc_birthday_card1.jpg",
      "./img/work/ctbc_birthday_card/ctbc_birthday_card2.jpg",
      "./img/work/ctbc_birthday_card/ctbc_birthday_card3.jpg",
      "./img/work/ctbc_birthday_card/ctbc_birthday_card4.jpg",
    ],
    completionDate: { year: "2024", month: "May" },
    liveDemo: "",
  },
  {
    id: 37,
    category: "graphic",
    urlName: "landbank_sustainable_architecture",
    name: "土地銀行 綠建築房貸海報設計",
    portfolioCover: require("../img/work/landbank_sustainable_architecture/landbank_sustainable_architecture1_thumbnail.jpg"),
    workCover: require("../img/work/landbank_sustainable_architecture/landbank_sustainable_architecture1_thumbnail.jpg"),
    gallery: [
      "./img/work/landbank_sustainable_architecture/landbank_sustainable_architecture1.jpg",
      "./img/work/landbank_sustainable_architecture/landbank_sustainable_architecture2.jpg",
    ],
    completionDate: { year: "2021", month: "Aug" },
    liveDemo: "",
  },
  {
    id: 38,
    category: "package",
    urlName: "taishin_fortune_water",
    name: "台新銀行 發財金與開運水設計",
    portfolioCover: require("../img/work/taishin_fortunate_water/taishin_fortunate_water_thumbnail.jpg"),
    workCover: require("../img/work/taishin_fortunate_water/taishin_fortunate_water_thumbnail.jpg"),
    gallery: [
      "./img/work/taishin_fortunate_water/taishin_fortunate_water1.jpg",
      "./img/work/taishin_fortunate_water/taishin_fortunate_water3.jpg",
      "./img/work/taishin_fortunate_water/taishin_fortunate_water2.jpg",
      "./img/work/taishin_fortunate_water/taishin_fortunate_water4.jpg",
      "./img/work/taishin_fortunate_water/taishin_fortunate_water5.jpg",
      "./img/work/taishin_fortunate_water/taishin_fortunate_water6.jpg",
    ],
    completionDate: { year: "2023", month: "Dec" },
    liveDemo: "",
  },
  {
    id: 39,
    category: "website",
    urlName: "taishin_loan",
    name: "台新銀行 信貸活動網頁設計",
    portfolioCover: require("../img/work/taishin_loan/taishin_loan1.jpg"),
    workCover: require("../img/work/taishin_loan/taishin_loan1.jpg"),
    gallery: [
      "./img/work/taishin_loan/taishin_loan1.jpg",
      "./img/work/taishin_loan/taishin_loan2.jpg",
      "./img/work/taishin_loan/taishin_loan3.jpg",
    ],
    completionDate: { year: "2024", month: "Mar" },
    liveDemo: "",
  },
  {
    id: 40,
    category: "graphic",
    urlName: "land_bank_marriott_bonvoy",
    name: "萬豪酒店X土地銀行 行銷活動",
    portfolioCover: require("../img/work/land_bank_marriott_bonvoy/land_bank_marriott_bonvoy1.jpg"),
    workCover: require("../img/work/land_bank_marriott_bonvoy/land_bank_marriott_bonvoy1.jpg"),
    gallery: [
      "./img/work/land_bank_marriott_bonvoy/land_bank_marriott_bonvoy1.jpg",
      "./img/work/land_bank_marriott_bonvoy/land_bank_marriott_bonvoy2.jpg",
      "./img/work/land_bank_marriott_bonvoy/land_bank_marriott_bonvoy4.jpg",
      "./img/work/land_bank_marriott_bonvoy/land_bank_marriott_bonvoy3.jpg",
    ],
    completionDate: { year: "2024", month: "Jun" },
    liveDemo: "",
  },
  {
    id: 41,
    category: "website",
    urlName: "mli_lunar_22",
    name: "三商美邦人壽 2022中秋賀卡",
    portfolioCover: require("../img/work/mli_lunar_2022/mli_lunar_2022_1.jpg"),
    workCover: require("../img/work/mli_lunar_2022/mli_lunar_2022_1.jpg"),
    gallery: [
      "./img/work/mli_lunar_2022/mli_lunar_2022_1.jpg",
      "./img/work/mli_lunar_2022/mli_lunar_2022_2.jpg",
      "./img/work/mli_lunar_2022/mli_lunar_2022_3.jpg",
    ],
    completionDate: { year: "2022", month: "???" },
    liveDemo: "",
  },
  {
    id: 42,
    category: "website",
    urlName: "taishin_trust",
    name: "台新銀行 最佳投號甜心活動網頁",
    portfolioCover: require("../img/work/taishin_trust/taishin_trust_2.jpg"),
    workCover: require("../img/work/taishin_trust/taishin_trust_2.jpg"),
    gallery: [
      "./img/work/taishin_trust/taishin_trust_1.jpg",
      "./img/work/taishin_trust/taishin_trust_2.jpg",
      "./img/work/taishin_trust/taishin_trust_3.jpg",
    ],
    completionDate: { year: "????", month: "???" },
    liveDemo: "",
  },
  {
    id: 43,
    category: "website",
    urlName: "taishin_trust",
    name: "台新銀行 產品齊備活動網頁",
    portfolioCover: require("../img/work/taishin_sweet/taishin_sweet_1.jpg"),
    workCover: require("../img/work/taishin_sweet/taishin_sweet_1.jpg"),
    gallery: [
      "./img/work/taishin_sweet/taishin_sweet_1.jpg",
      "./img/work/taishin_sweet/taishin_sweet_2.jpg",
      "./img/work/taishin_sweet/taishin_sweet_3.jpg",
    ],
    completionDate: { year: "????", month: "???" },
    liveDemo: "",
  },
  {
    id: 44,
    category: "website",
    urlName: "taishin_wisestar",
    name: "台新銀行 智多星問答活動網頁",
    portfolioCover: require("../img/work/taishin_wisestar/taishin_wisestar_1.jpg"),
    workCover: require("../img/work/taishin_wisestar/taishin_wisestar_1.jpg"),
    gallery: [
      "./img/work/taishin_wisestar/taishin_wisestar_1.jpg",
      "./img/work/taishin_wisestar/taishin_wisestar_2.jpg",
      "./img/work/taishin_wisestar/taishin_wisestar_3.jpg",
    ],
    completionDate: { year: "????", month: "???" },
    liveDemo: "",
  },
  {
    id: 45,
    category: "website",
    urlName: "sinopac_theater",
    name: "永豐銀行 薪轉大劇院",
    portfolioCover: require("../img/work/sinopac_theater/sinopac_theater_4.jpg"),
    workCover: require("../img/work/sinopac_theater/sinopac_theater_4.jpg"),
    gallery: [
      "./img/work/sinopac_theater/sinopac_theater_1.jpg",
      "./img/work/sinopac_theater/sinopac_theater_2.jpg",
      "./img/work/sinopac_theater/sinopac_theater_3.jpg",
      "./img/work/sinopac_theater/sinopac_theater_4.jpg",
      "./img/work/sinopac_theater/sinopac_theater_5.jpg",
    ],
    completionDate: { year: "2021", month: "???" },
    liveDemo: "",
  },
  {
    id: 46,
    category: "website",
    urlName: "taishin_midyear",
    name: "台新銀行 數位年中慶 活動網頁",
    portfolioCover: require("../img/work/taishin_midyear/taishin_midyear_5.jpg"),
    workCover: require("../img/work/taishin_midyear/taishin_midyear_5.jpg"),
    gallery: [
      "./img/work/taishin_midyear/taishin_midyear_1.jpg",
      "./img/work/taishin_midyear/taishin_midyear_2.jpg",
      "./img/work/taishin_midyear/taishin_midyear_3.jpg",
      "./img/work/taishin_midyear/taishin_midyear_4.jpg",
      "./img/work/taishin_midyear/taishin_midyear_5.jpg",
    ],
    completionDate: { year: "2021", month: "???" },
    liveDemo: "",
  },
  {
    id: 47,
    category: "website",
    urlName: "taishin_bond",
    name: "台新銀行 海外債券 活動網頁",
    portfolioCover: require("../img/work/taishin_bond/taishin_bond_2.jpg"),
    workCover: require("../img/work/taishin_bond/taishin_bond_2.jpg"),
    gallery: [
      "./img/work/taishin_bond/taishin_bond_1.jpg",
      "./img/work/taishin_bond/taishin_bond_2.jpg",
      "./img/work/taishin_bond/taishin_bond_3.jpg",
      "./img/work/taishin_bond/taishin_bond_4.jpg",
    ],
    completionDate: { year: "2023", month: "???" },
    liveDemo: "",
  },
  {
    id: 48,
    category: "website",
    urlName: "pictet_insurance",
    name: "百達投顧 保險手冊設計",
    portfolioCover: require("../img/work/pictet_insurance/pictet_insurance_1.jpg"),
    workCover: require("../img/work/pictet_insurance/pictet_insurance_1.jpg"),
    gallery: [
      "./img/work/pictet_insurance/pictet_insurance_1.jpg",
      "./img/work/pictet_insurance/pictet_insurance_2.jpg",
      "./img/work/pictet_insurance/pictet_insurance_3.jpg",
    ],
    completionDate: { year: "2023", month: "???" },
    liveDemo: "",
  },
  {
    id: 49,
    category: "graphic",
    urlName: "sc_welcome_letter_25",
    name: "渣打銀行 2025 優先私人理財 welcome letters",
    portfolioCover: require("../img/work/sc_welcome_letter_25/sc_welcome_letter_25_1.jpg"),
    workCover: require("../img/work/sc_welcome_letter_25/sc_welcome_letter_25_1.jpg"),
    gallery: [
      "./img/work/sc_welcome_letter_25/sc_welcome_letter_25_1.jpg",
      "./img/work/sc_welcome_letter_25/sc_welcome_letter_25_1.jpg",
      "./img/work/sc_welcome_letter_25/sc_welcome_letter_25_1.jpg",
    ],
    completionDate: { year: "2025", month: "Feb" },
    liveDemo: "",
  },
  {
    id: 50,
    category: "graphic",
    urlName: "sc_welcome_letter_25",
    name: "渣打銀行 2024 ABF 雜誌稿",
    portfolioCover: require("../img/work/sc_abf_magazine/sc_abf_magazine_1.jpg"),
    workCover: require("../img/work/sc_abf_magazine/sc_abf_magazine_1.jpg"),
    gallery: [
      "./img/work/sc_abf_magazine/sc_abf_magazine_1.jpg",
      "./img/work/sc_abf_magazine/sc_abf_magazine_2.jpg",
      "./img/work/sc_abf_magazine/sc_abf_magazine_3.jpg",
      "./img/work/sc_abf_magazine/sc_abf_magazine_4.jpg",
    ],
    completionDate: { year: "2024", month: "Dec" },
    liveDemo: "",
  },
  // {
  //   id: 49,
  //   category: "graphic",
  //   urlName: "twfhcsec_credit",
  //   name: "臺銀證券 信用報喜歡樂抽 海報設計",
  //   portfolioCover: require("../img/work/twfhcsec_credit/twfhcsec_credit_1.jpg"),
  //   workCover: require("../img/work/twfhcsec_credit/twfhcsec_credit_1.jpg"),
  //   gallery: [
  //     "./img/work/pictet_insurance/pictet_insurance_1.jpg",
  //     "./img/work/pictet_insurance/pictet_insurance_2.jpg",
  //     "./img/work/pictet_insurance/pictet_insurance_3.jpg",
  //   ],
  //   completionDate: { year: "2023", month: "???" },
  //   liveDemo: "",
  // },
];
